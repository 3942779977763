import { render, staticRenderFns } from "./AdminLayout.vue?vue&type=template&id=70dd95be&scoped=true"
import script from "./AdminLayout.vue?vue&type=script&lang=js"
export * from "./AdminLayout.vue?vue&type=script&lang=js"
import style0 from "@/assets/scss/vuetify/overrides.scss?vue&type=style&index=0&id=70dd95be&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "70dd95be",
  null
  
)

export default component.exports